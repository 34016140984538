<!-- 隐患排查详情 -->
<template>
  <scroll-layout class="details">
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <template v-for="(button, index) in pageButtons">
          <ykc-button
            :key="index"
            v-if="button.enabled"
            v-bind="button"
            @click="button.handleClick(button, $event)">
            {{ button.text }}
          </ykc-button>
        </template>
      </div>
    </template>
    <ykc-detail-plus class="box-card" :labelWidth="120">
      <div slot="header" class="clearfix-title">
        <span>隐患排查台账复检表详情</span>
      </div>
      <ykc-detail-item-plus label="电站名称">
        <span>{{ dealData(detail.stationName) }}</span>
      </ykc-detail-item-plus>
      <ykc-detail-item-plus label="隐患">
        <span>{{ dealData(detail.danger) }}</span>
      </ykc-detail-item-plus>
      <ykc-detail-item-plus label="责任人">
        <span>{{ dealData(detail.responsiblePerson) }}</span>
      </ykc-detail-item-plus>
      <ykc-detail-item-plus label="初检时间">
        <span>{{ dealData(detail.initialInspectDate) }}</span>
      </ykc-detail-item-plus>
      <ykc-detail-item-plus label="截止整改时间">
        <span>{{ dealData(detail.endRectifyDate) }}</span>
      </ykc-detail-item-plus>
      <ykc-detail-item-plus label="整改情况">
        <span>{{ detail.rectifyFlag === 0 ? '未整改' : '已整改' }}</span>
      </ykc-detail-item-plus>
      <ykc-detail-item-plus label="复查情况">
        <span>{{ detail.reviewFlag === 0 ? '未完成' : '已完成' }}</span>
      </ykc-detail-item-plus>
      <ykc-detail-item-plus label="复查人">
        <span>{{ dealData(detail.reviewPerson) }}</span>
      </ykc-detail-item-plus>
      <ykc-detail-item-plus label="完成时间">
        <span>{{ dealData(detail.finishDate) }}</span>
      </ykc-detail-item-plus>
      <ykc-detail-item-plus label="备注">
        <span>{{ dealData(detail.remark) }}</span>
      </ykc-detail-item-plus>
      <ykc-detail-item-plus label="附件">
        <viewer :images="detail.filePath">
          <img
            v-for="src in detail.filePath"
            :src="src"
            :key="src.title"
            alt=""
            width="80"
            height="80" />
        </viewer>
      </ykc-detail-item-plus>
    </ykc-detail-plus>
  </scroll-layout>
</template>

<script>
  import { omManagementApi } from '@/service/apis';
  import { dealData } from '@/utils/index';

  export default {
    name: 'hazardInvestigationDetail',
    data() {
      return {
        id: '',
        detail: {
          stationName: '',
          danger: '',
          responsiblePerson: '',
          initialInspectDate: '',
          endRectifyDate: '',
          rectifyFlag: '',
          reviewFlag: '',
          reviewPerson: '',
          finishDate: '',
          remark: '',
          filePath: [],
        }, // 详情数据
      };
    },
    computed: {
      pageButtons() {
        return [
          {
            text: '返回',
            type: 'plain',
            enabled: true,
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ];
      },
    },
    created() {
      this.id = Number(this.$route.query.id);
      this.requestDetailList();
    },
    methods: {
      dealData,
      /**
       * 获取详情数据
       */
      requestDetailList() {
        omManagementApi
          .dangerCheckDetail({ id: this.id })
          .then(res => {
            this.$nextTick(() => {
              if (res !== null) {
                Object.assign(this.detail, res);
              }
            });
          })
          .catch(err => {
            console.log('err', err);
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .box-card {
    .clearfix-title {
      padding: 10px;
      position: relative;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      font-size: 14px;
      line-height: 20px;
      &::after {
        content: ' ';
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
        border-bottom: 1px solid #e2e0e0;
      }
    }
    ::v-deep .el-card__body {
      margin: 30px 15px 0;
    }
    .detail-item span {
      word-break: break-all;
    }
    .detail-item img {
      margin-right: 20px;
    }
  }
</style>
